<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Dropdown
          :options="usersList"
          class="form-select form-control text-right"
          style="direction: rtl;width: 41%;margin-left: 8px;height: 100%;"
          optionLabel="name"
          optionValue="id"
          :filter="true"
          v-model.number="search.usersId"
          required
          placeholder="اسم الفني"
          :showClear="true"
        >
        </Dropdown>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()"
          v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      id="print"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه أوامر التركيب
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <Column
        field="contractsId.code"
        header="رقم العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['contractsId.code']"
            class="p-column-filter"
            placeholder="بحث برقم العقد"
          />
        </template>
      </Column>
      <!-- <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      /> -->
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      >
        <template #filter>
          <Dropdown
            :options="clientsList"
            class="form-select form-control text-right"
            style="direction:rtl"
            optionLabel="name"
            optionValue="name"
            :filter="true"
            v-model="filters['contractsId.clientsId.name']"
            placeholder="بحث باسم العميل"
            :showClear="true"
          />

          <!-- <select
            class="form-select form-control p-column-filter"
            id="clientsId"
            name="clientsId"
            v-model="filters['contractsId.clientsId.name']"
            placeholder="بحث باسم العميل"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select> -->
        </template>
      </Column>
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      >
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="branchesId"
            name="branchesId"
            v-model="filters['contractsId.branchesId.name']"
            placeholder="بحث بأسم الفرع"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of branchesList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column
        field="contractsId.date"
        header="تاريخ العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['contractsId.date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ العقد"
          />
        </template>
      </Column>
      <Column
        field="status"
        header="الحاله"
        :sortable="true"
        bodyStyle="text-align: center; overflow: visible"
      >
        <!-- filterMatchMode="contains" -->
        <template #body="slotProps">
          {{ $getStatus(slotProps.data.status) }}
        </template>
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="status"
            name="status"
            v-model="filters['status']"
            placeholder="بحث بالحاله"
          >
            <option :value="null">الكل </option>
            <option :value="'UnderDistribution'">تحت التوزيع</option>
            <option :value="'UnderOperation'">تحت التشغيل</option>
            <option :value="'UnderRevision'">تحت المرجعه</option>
            <option :value="'RevisionDone'">تحت التسليم الفني</option>
            <option :value="'backDelivery'">مرتجع من السكيرتاريه</option>
            <option :value="'UnderDelivery'">تحت التسليم</option>
            <option :value="'BackDelivery'">ملاحظات تسليم العملاء</option>
            <option :value="'DeliveryDone'">تم التسليم</option>
            <option :value="'DoneDeliveryCall'">تم اغلاق العقد</option>
          </select>
        </template>
      </Column>
      <Column
        field="contractsId.deliveryDate"
        :sortable="true"
        header="اقصى تاريخ تسليم"
      />
      <!-- <Column field="createdAt" header="تاريخ الانشاء">
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.createdAt) }}
        </template>
      </Column> -->

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data.contractsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="materialsNote" header="تفاصيل المواد الخام">
              <template #body="slotProps">
                <p v-html="slotProps.data.materialsNote"></p>
              </template>
            </Column>
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
            <Column field="status" header="حاله الخدمه">
              <template #body="slotProps2">
                <span
                  v-if="
                    slotProps.data.status == 'UnderDelivery' ||
                      slotProps.data.status == 'DeliveryDone'||
                      slotProps.data.status == 'DoneDeliveryCall'
                  "
                >
                  {{ $getStatus(slotProps.data.status) }}
                </span>
                <span v-else>
                  <span v-if="slotProps2.data.isReady">
                    تحت التسليم الفني
                  </span>
                  <span v-else>
                    {{
                      slotProps2.data.usersId
                        ? slotProps2.data.status == 'revision'
                          ? 'فى المراجعة'
                          : 'فى التشغيل'
                        : 'تحت التوزيع'
                    }}
                  </span>
                </span>

                <!-- {{
                  slotProps2.revision.length > 0
                    ? slotProps2.revision.length
                    : ''
                }} -->
              </template>
            </Column>
            <Column field="revision" header="عداد مرات المراجعة">
              <template #body="slotProps2">
                {{
                  slotProps2.data.revision ? slotProps2.data.revision.length : 0
                }}
              </template>
            </Column>
            <Column field="revision" header="مراجعات سابقه">
              <template #body="slotProps2">
                <Button
                  type="button"
                  @click="openListShow(slotProps2.data.revision)"
                  icon="pi pi-bell"
                  class="p-button-warning p-ml-2  p-button-rounded"
                ></Button>
              </template>
            </Column>

            <Column field="usersId.name" header="الموظف المسئول" />
          </DataTable>
          <DataTable
            :value="slotProps.data.statusList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه الحالات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column
              field="status"
              header="الحاله"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps3">
                {{ $getStatus(slotProps3.data.status) }}
              </template>
            </Column>

            <Column field="createdAt" header="تاريخ الانشاء">
              <template #body="slotProps3">
                {{ $durationFormatFull(slotProps3.data.createdAt) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,

      filters: {},
      branchesList: [],
      clientsList: [],
      usersList: [],
      listx: [],
      search: {
        start: null,
        end: null,
        usersId: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.contractsId.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.contractsId.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.contractsId.createdAt).getTime() >= start &&
            new Date(item.contractsId.createdAt).getTime() <= end
          );
        });
      }

      if (!this.search.start && !this.search.end) {
        this.list = this.listx;
      }

      if (this.search.usersId) {
        const list = [];
        for (const item of this.list) {
          for (const itm of item.contractsId.productsList) {
            if (itm.usersId && itm.usersId.id == this.search.usersId) {
              list.push(item);
            }
          }
        }
        this.list = list;
      }
    },
    getData() {
      this.$http.get(`installations/getAllDataReport2`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>
